import React from 'react'

class Accordion extends React.Component {
  constructor(props) {
    super(props)
    this.collapsibleRef = React.createRef()
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      import('@ef-global/web-ui/js/ef-collapsible/ef-collapsible').then(
        efcollapsible => {
          new efcollapsible.EfCollapsible(this.collapsibleRef.current)
        }
      )
    }
  }

  render() {
    const header = React.Children.map(this.props.header, header => {
      return React.cloneElement(header, {
        ref: this.collapsibleRef,
        'data-target': `#accordion-${this.props.id}`,
        'data-breakpoints': 's',
        className: header.props.className + ' ef-collapsible__toggle -icon',
      })
    })

    return (
      <div className="ef-collapsible">
        {header}
        <div
          className="ef-collapsible__content"
          id={`accordion-${this.props.id}`}
        >
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default Accordion
