import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { connect } from 'react-redux'
import { trim } from 'lodash'
import { globalHistory } from '@reach/router'
import AnimateHeight from 'react-animate-height'
import IconChevronDown from '@ef-global/web-ui-react/lib/icons/IconChevronDown'

import SidebarLink from './sidebar-link'
import { toggleMenu } from '../../state/app'

const mapDispatchToProps = {
  toggleMenu,
}

class SidebarAccordion extends React.Component {
  constructor(props) {
    super(props)

    this.parentFolder = this.props.link
    this.indexLink =
      this.parentFolder.children &&
      this.parentFolder.children.find(link => link.is_startpage)
    this.links =
      this.parentFolder.children &&
      this.parentFolder.children.filter(link => !link.is_startpage)

    this.state = {
      isMenuOpen: false,
    }

    this._handleToggleAccordion = this._handleToggleAccordion.bind(this)
  }

  componentDidMount() {
    this.setState({ isMenuOpen: this.checkMenuState() })
  }

  _handleToggleAccordion() {
    this.setState({
      isMenuOpen: !this.state.isMenuOpen,
    })
  }

  _handleLinkClick() {
    if (!this.checkMenuState() && this.state.isMenuOpen) return
    this.setState({
      isMenuOpen: !this.state.isMenuOpen,
    })
  }

  checkMenuState() {
    return (
      (this.parentFolder.children &&
        this.parentFolder.children.filter(link => {
          return (
            trim(link.slug, '/') === trim(globalHistory.location.pathname, '/')
          )
        }).length > 0) ||
      (this.indexLink &&
        trim(this.indexLink.slug, '/') ===
          trim(globalHistory.location.pathname, '/'))
    )
  }

  render() {
    return this.links && this.links.length > 0 ? (
      <li
        className={`sidebar__item ${this.state.isMenuOpen ? '-is-open' : ''} ${
          trim(globalHistory.location.pathname, '/') === this.parentFolder.slug
            ? '-is-active'
            : ''
        }`}
        key={this.parentFolder.id}
      >
        <div className="sidebar__accordion-btn">
          {this.indexLink !== undefined ? (
            <Link
              to={`/${this.indexLink.slug}`}
              className="sidebar__link"
              activeClassName="-is-active"
              onClick={() => {
                this._handleLinkClick()
                this.props.toggleMenu()
              }}
            >
              {this.indexLink.name}
            </Link>
          ) : (
            <button
              className="sidebar__link"
              onClick={this._handleToggleAccordion}
            >
              {this.parentFolder.name}
            </button>
          )}
          <IconChevronDown onClick={this._handleToggleAccordion} />
        </div>
        <AnimateHeight
          duration={300}
          height={this.state.isMenuOpen ? 'auto' : 0}
          animateOpacity={true}
          className="disable-aria-display" //to avoid messing with animation calcs
        >
          <ul className="sidebar__sub-menu">
            {this.links.map(link => (
              <SidebarLink link={link} key={link.id} />
            ))}
          </ul>
        </AnimateHeight>
      </li>
    ) : this.indexLink ? (
      <SidebarLink link={this.indexLink} key={this.indexLink.id} />
    ) : null
  }
}

SidebarAccordion.propTypes = {
  parentFolder: PropTypes.object,
  links: PropTypes.array,
}

export default connect(null, mapDispatchToProps)(SidebarAccordion)
