const initialState = {
  isMenuOpen: false,
}

const TOGGLE_MENU = 'TOGGLE_MENU'
export const toggleMenu = isMenuOpen => ({
  type: TOGGLE_MENU,
  isMenuOpen,
})

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_MENU:
      return { ...state, isMenuOpen: action.isMenuOpen }
    default:
      return state
  }
}
