import { graphql, useStaticQuery } from 'gatsby'
import parseStoryFromNode from '../helpers/parseStory'

export function useIsInEditorHook() {
  let isIn = false

  if (typeof window !== `undefined`) {
    const fullUrl = window.location.href
    isIn = fullUrl.search('_storyblok') > -1
  }

  return isIn
}

const queryAllStoryblokEntries = graphql`
  query {
    allStoryblokEntry {
      edges {
        node {
          field_component
          id
          name
          created_at
          published_at
          uuid
          slug
          full_slug
          content
          is_startpage
          parent_id
          group_id
        }
      }
    }
  }
`

export const useAllStoryblokEntries = () => {
  const { allStoryblokEntry } = useStaticQuery(queryAllStoryblokEntries)
  return allStoryblokEntry
}

export const useAllStoryblokEntriesParsed = () => {
  return useAllStoryblokEntries().edges.map(edge => {
    return parseStoryFromNode(edge)
  })
}

export const useAllStoriesWithParent = parentId => {
  const allStoryblokEntry = useAllStoryblokEntries()

  const sectionNode = allStoryblokEntry.edges
    .filter(edge => edge.node.parent_id === parentId)
    .map(edge => {
      return parseStoryFromNode(edge)
    })

  return sectionNode.length && sectionNode
}

export const useStartpageFromParent = parentId => {
  const allStoryblokEntry = useAllStoryblokEntries()

  const sectionNode = allStoryblokEntry.edges.filter(
    edge => edge.node.parent_id === parentId && edge.node.is_startpage
  )

  return sectionNode.length && parseStoryFromNode(sectionNode[0])
}

export const useStartpageFromParents = stories => {
  const allStoryblokEntry = useAllStoryblokEntries()

  return stories.map(story => {
    const startNode = allStoryblokEntry.edges.find(edge => {
      return (
        edge.node.parent_id === story.item.parent_id && edge.node.is_startpage
      )
    })

    return (story.startStory =
      startNode !== undefined && parseStoryFromNode(startNode))
  })
}

export const generateListOfSection = stories => {
  if (stories === 0) {
    return []
  }

  return stories
    .filter(story => !story.is_startpage)
    .filter(story => story.field_component === 'subsection-page')
}
