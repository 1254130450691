import PropTypes from 'prop-types'
import React from 'react'
import IconMenu from '@ef-global/web-ui-react/lib/icons/IconMenu'
import IconClose from '@ef-global/web-ui-react/lib/icons/IconClose'

import { StaticQuery, graphql } from 'gatsby'
import { connect } from 'react-redux'
import { toggleMenu } from '../../state/app'
import Search from '../Search/search'
import ProductChooser from '../ProductChooser/product-chooser'
import { isEmpty } from 'lodash'

const mapDispatchToProps = {
  toggleMenu,
}

class Header extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query SearchIndexQuery {
            siteSearchIndex {
              index
            }
          }
        `}
        render={data => (
          <header className={`ef-header -global ${this.props.modifiers}`}>
            <div className="ef-header__group -global">
              <div className="ef-header__group__inner">
                <div className="ef-header__item ef-header__col -left">
                  <button
                    className={`header__menu-toggle ${
                      this.props.showNavToggle ? '' : 'u-show-m-down'
                    }`}
                    onClick={() => this._handleToggleSidebar()}
                  >
                    {this.props.isMenuOpen ? <IconClose /> : <IconMenu />}
                  </button>
                </div>
                <div className="ef-header__item ef-header__col -center">
                  <ProductChooser
                    products={
                      !isEmpty(this.props.settings) &&
                      this.props.settings.content.products_nav
                    }
                  >
                    <span className="ef-header__title">
                      <span className="u-show-m-up">
                        {isEmpty(this.props.productStory)
                          ? 'EF / Grand Unified Design'
                          : this.props.productStory.name}
                      </span>
                      <span className="u-show-s-only">
                        {isEmpty(this.props.productStory)
                          ? 'EF / GUD'
                          : this.props.productStory.name}
                      </span>
                    </span>
                  </ProductChooser>
                </div>
                <div className="ef-header__item ef-header__col -right">
                  <Search
                    searchIndex={data.siteSearchIndex.index}
                    productSlug={this.props.productSlug}
                    productName={this.props.productStory.name}
                  />
                </div>
              </div>
            </div>
          </header>
        )}
      />
    )
  }

  _handleToggleSidebar() {
    this.props.toggleMenu(!this.props.isMenuOpen)
  }
}

Header.propTypes = {
  modifiers: PropTypes.string,
  story: PropTypes.object,
  productStory: PropTypes.object,
  productSlug: PropTypes.string,
}

Header.defaultProps = {
  modifiers: '',
  story: {},
  productStory: {},
  productSlug: '',
}

export default connect(state => {
  return {
    isMenuOpen: state.app.isMenuOpen,
  }
}, mapDispatchToProps)(Header)
