import React from 'react'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import IconMail from '@ef-global/web-ui-react/lib/icons/IconMail'

import Accordion from '../Accordion/accordion'
import Newsletter from '../newsletter'

class Footer extends React.Component {
  render() {
    return (
      <footer className={`footer ${this.props.className}`}>
        <div className="ef-container">
          <div className="ef-row">
            <div className="ef-col -s-12 -m-6">
              <Accordion
                id={'description'}
                header={
                  <h4 className="ef-footer__title footer__brand">
                    EF <span className="u-f-black">/</span> GRAND UNIFIED DESIGN
                  </h4>
                }
              >
                <ul
                  className="ef-list -small ef-footer__list"
                  data-dd="footer-description"
                >
                  <li className="ef-list__item">
                    EF Grand Unified Design is an adaptable system of guidelines
                    and tools that help our distinct EF products appear related
                    at a glance. Grand Unified Design is a collaboration between
                    designers and developers with the aim of creating
                    consistency across EF touchpoints.
                  </li>
                </ul>
                <Newsletter />
              </Accordion>
            </div>

            <div className="ef-col -s-12 -m-3">
              <Accordion
                id={'resources'}
                header={<h4 className="ef-footer__title">Resources</h4>}
              >
                <ul
                  className="ef-list -small ef-footer__list"
                  data-dd="footer-resources"
                >
                  <li className="ef-list__item">
                    <OutboundLink
                      href="https://code.ef.design/?path=/story/home--overview"
                      className="ef-footer__link"
                    >
                      Pattern Library
                    </OutboundLink>
                  </li>
                  <li className="ef-list__item">
                    <OutboundLink
                      href="https://ef.design/"
                      className="ef-footer__link"
                    >
                      EF.design
                    </OutboundLink>
                  </li>
                  <li className="ef-list__item">
                    <OutboundLink
                      href="https://efcom.sharepoint.com/sites/globalnet-hub"
                      className="ef-footer__link"
                    >
                      Globalnet
                    </OutboundLink>
                  </li>
                </ul>
              </Accordion>
            </div>

            <div className="ef-col -s-12 -m-3">
              <Accordion
                id={'global-creative'}
                header={
                  <h4 className="ef-footer__title">Contribution & Feedback</h4>
                }
              >
                <ul className="ef-list -small ef-footer__list">
                  <li className="ef-list__item">
                    <OutboundLink
                      href="mailto:global.creative@ef.com"
                      className="ef-footer__link"
                    >
                      <IconMail />
                      Send us an Email
                    </OutboundLink>
                  </li>
                  <li className="ef-list__item">
                    <OutboundLink
                      href="https://teams.microsoft.com/l/team/19%3a8c224395df0d425ea22282b97a7cbb26%40thread.skype/conversations?groupId=75caac76-cab1-4988-9a4a-da0c963e1639&tenantId=f0d1c6fd-dff0-486a-8e91-cfefefc7d98d"
                      className="ef-footer__link"
                    >
                      <span className="ef-icon -social-msteams" />
                      Chat with us
                    </OutboundLink>
                  </li>
                </ul>
              </Accordion>
            </div>
          </div>

          <div className="ef-footer__lang-section">
            <div className="ef-footer__lang-selector-w">
              <span className="ef-footer__link">
                &copy; EF Education First {new Date().getFullYear()}. All rights
                reserved.
              </span>
              <span className="ef-footer__link">
                Made with{' '}
                <svg
                  className="footer__heart"
                  viewBox="0 0 32 32"
                  width="14"
                  height="14"
                >
                  <path d="M28 5.45A8.23 8.23 0 0 0 22.19 3a8.2 8.2 0 0 0-5.83 2.45L16 5.8l-.35-.35a8.18 8.18 0 0 0-11.58-.1l-.1.1A8.44 8.44 0 0 0 4 17.24L15.29 28.7a1 1 0 0 0 1.42 0L28 17.24a8.46 8.46 0 0 0 0-11.79zm-23.35.7z" />
                </svg>{' '}
                in Lucerne
              </span>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
