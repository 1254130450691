import React from 'react'
import enhanceWithClickOutside from 'react-click-outside'
import IconChevronDown from '@ef-global/web-ui-react/lib/icons/IconChevronDown'

import Link from '../Link/link'
import FocusPointBg from '../FocusPoint/focusPointBg'
import { trackEvent } from '../../helpers/tracking'

class ProductChooser extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
    }
  }

  handleClickOutside() {
    this.setState({ isOpen: false })
  }

  render() {
    return (
      this.props.products && (
        <div>
          <button
            onClick={() => {
              !this.state.isOpen && trackEvent('Product navigation', 'open')
              this.setState({
                isOpen: !this.state.isOpen,
              })
            }}
            className={`prod-chooser__toggle ${
              this.state.isOpen ? '-is-open' : ''
            }`}
          >
            {this.props.children}
            <IconChevronDown />
          </button>

          <div
            className={`prod-chooser ${this.state.isOpen ? '-is-open' : ''}`}
          >
            <div className="ef-container">
              <section className="prod-chooser__list">
                {this.props.products.map(product => (
                  <Link
                    storyblokLink={product.link}
                    className="prod-chooser__prod"
                    key={product._uid}
                    onClick={() => {
                      trackEvent(
                        'Product navigation',
                        'click',
                        `${product.name}`
                      )
                      this.setState({
                        isOpen: false,
                      })
                    }}
                  >
                    <FocusPointBg
                      className="prod-chooser__prod-bg"
                      focusPoint={product.background_img}
                      size={{ width: 300, height: 300 }}
                    />
                    <div className="prod-chooser__prod-content">
                      <p
                        className="prod-chooser__prod-tag"
                        style={{
                          color: product.text_color && product.text_color.color,
                        }}
                      >
                        Guidelines
                      </p>
                      <span
                        style={{
                          color: product.text_color && product.text_color.color,
                        }}
                      >
                        {product.name}
                      </span>
                    </div>
                  </Link>
                ))}
              </section>
            </div>
          </div>
        </div>
      )
    )
  }
}

export default enhanceWithClickOutside(ProductChooser)
