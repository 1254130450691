import React from 'react'
import { Location } from '@reach/router'
import { connect } from 'react-redux'
import { trim } from 'lodash'
import { Link } from 'gatsby'
import { toggleMenu } from '../../state/app'

const mapDispatchToProps = {
  toggleMenu,
}

const SidebarLink = props => {
  return (
    <Location>
      {({ location }) => {
        const isLinkActive =
          trim(location.pathname, '/') === trim(props.link.slug, '/') ||
          (location.pathname === '/' && props.link.slug === 'home')

        return (
          <li className={`sidebar__item ${isLinkActive ? '-is-active' : ''}`}>
            <Link
              to={props.link.slug === 'home' ? '/' : `/${props.link.slug}`}
              className={`sidebar__link ${isLinkActive ? '-is-active' : ''}`}
              onClick={() => props.toggleMenu()}
            >
              {props.link.name}
            </Link>
          </li>
        )
      }}
    </Location>
  )
}

export default connect(null, mapDispatchToProps)(SidebarLink)
