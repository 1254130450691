import React from 'react'
import { Transition, CSSTransition } from 'react-transition-group'
import { connect } from 'react-redux'
import IconClose from '@ef-global/web-ui-react/lib/icons/IconClose'

import SidebarLink from './sidebar-link'
import SidebarAccordion from './sidebar-accordion'
import { toggleMenu } from '../../state/app'
import activeBreakpoint from '../../helpers/breakpoint'

const mapDispatchToProps = {
  toggleMenu,
}

class Sidebar extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      indicatorTop: 0,
      previousIndicatorElem: null,
    }

    this.navRef = React.createRef()
    this._handleResize = this._handleResize.bind(this)
  }

  componentDidMount() {
    window.addEventListener('resize', this._handleResize)
    this.updateIndicators()
    this.finishMovingIndicator()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._handleResize)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isMenuOpen !== this.props.isMenuOpen) {
      if (this.props.isMenuOpen) {
        document.documentElement.classList.add('sidebar-opened')
        document.body.classList.add('sidebar-opened')
      } else {
        document.documentElement.classList.remove('sidebar-opened')
        document.body.classList.remove('sidebar-opened')
      }
    }

    if (prevProps.story && prevProps.story.id !== this.props.story.id) {
      this.updateIndicators()
    }
  }

  updateIndicators() {
    const activeItem = this.navRef.current.getElementsByClassName(
      'sidebar__link -is-active'
    )

    if (activeItem.length) {
      this.setState({
        indicatorTop: activeItem[0].parentElement.offsetTop,
        indicatorActive: true,
      })
    }
  }

  finishMovingIndicator() {
    const prevIndicator = this.navRef.current.getElementsByClassName(
      'sidebar__link -is-active'
    )
    this.setState({
      indicatorActive: false,
      previousIndicatorElem:
        prevIndicator.length > 0 && prevIndicator[0].parentElement,
    })
  }

  render() {
    return (
      <CSSTransition
        in={this.props.isMenuOpen}
        timeout={200}
        classNames="sidebar"
      >
        <aside className={`sidebar ${this.props.collapse ? '-collapsed' : ''}`}>
          <button
            className="sidebar__close"
            onClick={() => this.props.toggleMenu()}
          >
            <IconClose />
          </button>
          <nav className={`sidebar__nav`} ref={this.navRef}>
            <Transition
              in={this.state.indicatorActive}
              timeout={300}
              exit={false}
              onEntered={() => this.finishMovingIndicator()}
            >
              {state => {
                const defaultStyles = {
                  transform:
                    this.state.previousIndicatorElem &&
                    `translateY(${this.state.previousIndicatorElem.offsetTop}px)`,
                }

                const transitions = {
                  entering: {
                    opacity: 1,
                    transition:
                      this.state.previousIndicatorElem &&
                      this.state.previousIndicatorElem.offsetParent
                        ? 'transform 0.3s'
                        : 'opacity 0.3s',
                    transform: `translateY(${this.state.indicatorTop}px)`,
                  },
                }
                return (
                  <div
                    className="sidebar__indicator"
                    style={{ ...defaultStyles, ...transitions[state] }}
                  />
                )
              }}
            </Transition>
            <ul className="sidebar__menu">
              {this.props.links.map(link => {
                return !link.is_folder ? (
                  <SidebarLink key={link.id} link={link} />
                ) : (
                  <SidebarAccordion key={link.id} link={link} />
                )
              })}
            </ul>
          </nav>
          <button
            className="sidebar__overlay"
            onClick={() => this.props.toggleMenu(false)}
          />
        </aside>
      </CSSTransition>
    )
  }

  _handleResize() {
    this.resizeTimer && clearTimeout(this.resizeTimer)
    this.resizeTimer = setTimeout(() => {
      this.resizeTimer = null

      if (['s', 'm'].indexOf(activeBreakpoint()) === -1) {
        this.props.isMenuOpen && this.props.toggleMenu(false)
      }
    }, 300)
  }
}

export default connect(state => {
  return {
    isMenuOpen: state.app.isMenuOpen,
  }
}, mapDispatchToProps)(Sidebar)
