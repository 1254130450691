import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

import { useIsInEditorHook } from '../hooks/storyblokEntries'
import canUseWebp from '../helpers/webpSupport'

const BgImgLazyLoad = props => {
  const isInEditor = useIsInEditorHook()
  const [image, setImage] = useState(null)

  const beforeLoad = () => {
    const { imgSrc, imgWebpSrc } = props
    const imageSrc = canUseWebp() && imgWebpSrc !== '' ? imgWebpSrc : imgSrc

    const img = new Image()
    img.src = imageSrc

    img.onload = () => {
      setImage(imgSrc)
    }
  }

  // we have to pass placeholder here with some height/width, otherwise,
  // react-lazy-load-image-component will not handle lazy loading properly
  // (while default, they initialize it  without dimensions) which leads to problems
  const placeholder = <section style={{ height: '1px', width: '1px' }} />

  return isInEditor ? (
    props.render({ image: props.imgSrc })
  ) : (
    <LazyLoadComponent
      beforeLoad={beforeLoad}
      placeholder={placeholder}
      threshold={300}
    >
      {props.render({ image })}
    </LazyLoadComponent>
  )
}

BgImgLazyLoad.defaultProps = {
  imgWebpSrc: '',
}

BgImgLazyLoad.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  imgWebpSrc: PropTypes.string,
}

export default BgImgLazyLoad
