import { globalHistory } from '@reach/router'
import arrayToTree from 'array-to-tree'
import cookie from 'cookie'
import { graphql, StaticQuery } from 'gatsby'
import { navigate } from 'gatsby-link'
import PropTypes from 'prop-types'
import React from 'react'
import Footer from '../components/Footer/footer'
import Header from '../components/Header/header'
import Sidebar from '../components/Sidebar/sidebar'
import { useIsInEditorHook } from '../hooks/storyblokEntries'
import '../styles/main.scss'

class Layout extends React.Component {
  isInEditor = useIsInEditorHook()
  constructor(props) {
    super(props)

    this.state = {
      isLoggedIn: false,
    }
  }

  componentDidMount() {
    const { profile } = document && cookie.parse(document.cookie)

    this.setState({
      isLoggedIn: !!profile,
    })

    if (typeof window !== 'undefined') {
      if (window.location.search.indexOf('temporaryLogin') !== -1) {
        document.cookie = cookie.serialize('tempLogin', 'true', {
          path: '/',
          expires: new Date('2021'),
        })
      }
      const { tempLogin } = cookie.parse(document.cookie)
      const isLocalhost =
        window.location.host.indexOf('localhost') !== -1 ||
        window.location.host.indexOf('127.0.0.1') !== -1 ||
        window.location.host.indexOf('--gud.netlify.app') !== -1 ||
        !!tempLogin

      if (
        !profile &&
        globalHistory.location.pathname !== `/login` &&
        !this.isInEditor &&
        !isLocalhost
      ) {
        // If the user is not logged in, redirect to the login page.
        navigate(`/login?returnUrl=${globalHistory.location.pathname}`)
        return null
      }
    }
  }

  prepareData(data, story) {
    let sidebarRoot = 0
    let sidebarLinks = []
    let productStory = {}
    let productSlug = ''

    const links = data.allStoryblokLink.edges
      .filter(link => {
        return !/.*assets\/.+/i.test(link.node.slug) // Exclude assets
      })
      .map(link => {
        if (link.node.slug.includes('products/')) {
          const splittedOldSlug = link.node.slug.split('/')
          splittedOldSlug.shift()
          const slug = splittedOldSlug.join('/')

          return {
            node: {
              ...link.node,
              slug,
            },
          }
        }
        return link
      })
      .map(link => {
        return link.node
      })

    const linksTree = arrayToTree(links, { customID: 'internalId' })

    if (story && story.full_slug.startsWith('products/')) {
      const storyParent = story.full_slug.match(/products\/(.+?)\/.*/)
      if (storyParent && storyParent.length === 2) {
        const storyProduct = links.find(link => {
          // here we look for the main folder of the project selected
          return link.slug === storyParent[1]
        })

        sidebarRoot = storyProduct.id
        productStory = storyProduct
        productSlug = storyParent[1]
      }
    }

    if (sidebarRoot === 0) {
      sidebarLinks = linksTree.filter(link => {
        return link.slug !== 'products'
      })
    } else {
      // for products sidebar
      // get all available products
      const products = linksTree.find(link => {
        return link.slug === 'products'
      }).children
      // get links of required product
      sidebarLinks = products.find(link => {
        return link.id === sidebarRoot
      }).children
    }

    return { sidebarLinks, productStory, productSlug }
  }

  render() {
    let isLocalhost = false

    if (typeof window !== 'undefined') {
      const { tempLogin } = cookie.parse(document.cookie)
      isLocalhost =
        window.location.host.indexOf('localhost') !== -1 ||
        window.location.host.indexOf('127.0.0.1') !== -1 ||
        window.location.host.indexOf('--gud.netlify.app') !== -1 ||
        !!tempLogin
    }

    const startPage =
      this.props.pageContext.story &&
      (this.props.pageContext.story.slug === 'home' ||
        (this.props.pageContext.story.is_startpage &&
          this.props.pageContext.story.full_slug.split('/').length < 4))

    return (
      <StaticQuery
        query={queryAllStoryblokLinks}
        render={data => {
          const { sidebarLinks, productStory, productSlug } = this.prepareData(
            data,
            this.props.pageContext.story
          )

          return this.state.isLoggedIn || this.isInEditor || isLocalhost ? (
            <div>
              <Header
                story={this.props.pageContext.story}
                settings={this.props.settings}
                productSlug={productSlug}
                productStory={productStory}
                showNavToggle={startPage}
              />
              <Sidebar
                collapse={startPage}
                story={this.props.pageContext.story}
                links={sidebarLinks}
              />
              <div className={`body ${startPage ? '-full' : '-with-sidebar'}`}>
                {this.props.children}
              </div>
              <Footer className={`${startPage ? '-full' : '-with-sidebar'}`} />
            </div>
          ) : null
        }}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

const queryAllStoryblokLinks = graphql`
  query {
    allStoryblokLink(
      filter: { slug: { regex: "/^(?!settings.*)/i" } }
      sort: { fields: position }
    ) {
      edges {
        node {
          id
          internalId
          slug
          name
          is_folder
          parent_id
          position
          uuid
          is_startpage
          real_path
        }
      }
    }
  }
`
