import React from 'react'
import { SwitchTransition, CSSTransition } from 'react-transition-group'

class Newsletter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      subscribed: false,
      isLoading: false,
      hasError: false,
      errorMessage: 'There was an error, please try again.',
    }
    this.handleNewsletterSignup = this.handleNewsletterSignup.bind(this)
  }

  async handleNewsletterSignup(e) {
    e.preventDefault()

    this.setState({ isLoading: true })
    try {
      const response = await fetch('/api/newsletter-subscribe', {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          accept: 'Accept: application/json',
        },
        body: JSON.stringify({ email: this.state.email }),
      })

      if (response.ok) {
        this.setState({ subscribed: true })
      } else {
        if (response.status === 400) {
          // possibly fake email or already subscribed
          this.setState({
            errorMessage: `${this.state.email} is already a member.`,
          })
        } else {
          this.setState({
            errorMessage: 'There was an error, please try again.',
          })
        }
        this.setState({ hasError: true })
      }
    } catch (error) {
      this.setState({ hasError: true })
    }

    this.setState({ isLoading: false })
  }

  render() {
    return (
      <SwitchTransition>
        {!this.state.subscribed ? (
          <CSSTransition
            key="too-subscribe"
            timeout={200}
            classNames="newsletter--fade"
          >
            <div className="u-mb-m">
              <h6 className="ef-text-ui text--bold">
                Sign up for monthly G.U.D. updates
              </h6>
              <form
                className="newsletter__form"
                onSubmit={this.handleNewsletterSignup}
              >
                <div className="u-mr-xs u-mb-xs">
                  <div className="ef-input-w">
                    <input
                      name="email"
                      className="ef-input"
                      type="email"
                      required
                      placeholder="Email address"
                      value={this.state.email}
                      disabled={this.state.isLoading}
                      onChange={event =>
                        this.setState({ email: event.target.value })
                      }
                    />
                  </div>
                </div>
                <div>
                  {!this.state.isLoading ? (
                    <button
                      type="submit"
                      className="ef-button -dark -secondary -square"
                    >
                      Sign up
                    </button>
                  ) : (
                    <button
                      type="submit"
                      disabled
                      className="ef-button -secondary -dark -square -is-loading"
                    >
                      <span style={{ color: 'transparent' }}>Sign up</span>
                    </button>
                  )}
                </div>
                {this.state.hasError && (
                  <div className="ef-col -s-12">
                    <span style={{ color: '#fa2121', fontSize: '12px' }}>
                      {this.state.errorMessage}
                    </span>
                  </div>
                )}
              </form>
            </div>
          </CSSTransition>
        ) : (
          <CSSTransition
            key="subscribed"
            timeout={200}
            classNames="newsletter--fade"
          >
            <div className="ef-text-ui text--bold u-mb-l">
              <span className="ef-icon -check-circle" /> Thank you! We will add
              you to the Emailing list
            </div>
          </CSSTransition>
        )}
      </SwitchTransition>
    )
  }
}

export default Newsletter
