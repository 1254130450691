const IS_WEBP_SUPPORTED = 'isWebpSupported'

export default function canUseWebp() {
  if (typeof document !== 'object') {
    // not in browser
    return false
  }
  let isWebpSupported = sessionStorage.getItem(IS_WEBP_SUPPORTED)

  if (isWebpSupported === null) {
    isWebpSupported =
      document
        .createElement('canvas')
        .toDataURL('image/webp')
        .indexOf('data:image/webp') === 0

    sessionStorage.setItem(IS_WEBP_SUPPORTED, isWebpSupported)
  }

  return isWebpSupported === 'true'
}
